<template>
  <div id="test">
      <!-- todo dentro de aqui. -->
      <div class="bloquemain bmp">
        <img src="../assets/logocima2.png" alt="Logo Cimarrones">
        <h1>PRÓXIMAMENTE</h1>
        <h5>SIGUIENTE PARTIDO</h5>
        <div class="bloquepartido bppc">
          <div class="coluno-bp">
            <div class="bloquefecha">
              <h2 class="txtfe1">14</h2>
              <h4 class="txtfe1">NOV</h4>
              <h6 class="txtfe1">11:00</h6>
            </div>
          </div>
          <div class="coldos-bp">
            <h3>Jornada 16</h3>
            <p>Estadio: Jalisco</p>
            <div class="filaequipos">
              <div class="coluno-fp">
                <img class="logovisp" src="../assets/leones.png" alt="Equipo Visitante">
                <h5>LEONES NEGROS DE LA U.D.G.</h5>
              </div>
              <div class="coldos-fp">
                <h4 class="txtvs-p">VS</h4>
              </div>
              <div class="coltres-fp">
                <img src="../assets/logoloc.png" alt="Equipo Local">
                <h5>CIMARRONES DE SONORA</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="bloquepartido bpmov">
          <div class="coluno-bp">
            <div class="bloquefecha">
              <h4 class="txtfe1">14 NOV</h4>
              <h6 class="txtfe1">11:00</h6>
            </div>
            <div class="bloquejornada">
              <h3>Jornada 13</h3>
              <p>Estadio: Héroe de Nacozari</p>
            </div>
          </div>
          <div class="coldos-bp">
            <div class="filaequipos">
              <div class="coluno-fp">
                <img src="../assets/logoloc.png" alt="Equipo Visitante">
                <h5>CIMARRONES DE SONORA</h5>
              </div>
              <div class="coldos-fp">
                <h4 class="txtvs-p">VS</h4>
              </div>
              <div class="coltres-fp">
                <img class="logovisp" src="../assets/TLAXCALA.png" alt="Equipo Local">
                <h5>TLAXCALA FC</h5>
              </div>
            </div>
          </div>
        </div>
        <a href="https://boletomovil.com/cimarrones-sonora" target="_blank">
                <div class="btnboletos">
                    <p>COMPRA TUS BOLETOS AQUÍ</p>
                    <img src="../assets/Arrowpro.png">
                </div>
            </a>
            <div class="bloquesocial">
                    <a href="https://www.facebook.com/CimarronesFC" target="_blank">
                        <img class="fbip" src="../assets/fbp.png">
                    </a>
                    <a href="https://www.instagram.com/cimarronesfc" target="_blank">
                        <img class="igip" src="../assets/igip.png">                        
                    </a>
                    <a href="https://mx.linkedin.com/company/cimarronessonora" target="_blank">
                        <img class="liip" src="../assets/inp.png">                        
                    </a>
                    <a href="https://twitter.com/CimarronesFC" target="_blank">
                        <img class="twip" src="../assets/twp.png">                        
                    </a>
            </div>
      </div>

  </div>
</template>
<script>


export default {
  name: 'test',


  /**titulo pagina */
  watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || 'PROXIMAMENTE - Cimarrones de Sonora';
            }
        },
    }
}
</script>

<!-- estilos -->
<style scoped>


#test{
    background: white;
    height: 100%;
}

@media (min-width: 1024px ) {
.bloquemain {
  background-image: url(../assets/FONDOPROX.png);
  background-repeat: no-repeat;
  background-size: 100%;
  text-align: center;
  padding-top: 3.6458333333333335VW;
}
.bmp img {
  width: 8.90625VW;
  padding-bottom: 2.9166666666666665VW;
}
.bmp h1 {
  color: #032a4b;
  font-family: Rajdhani;
  font-size: 3.6458333333333335VW;
  font-weight: 800;
  letter-spacing: 0.2vw;
  margin: 0;
  padding-bottom: 2.604166666666667VW;
}
.bmp h5 {
  color: #ce1526;
  font-family: Rajdhani;
  font-weight: 700;
  font-size: 1.0416666666666665VW;
  margin: 0;
  padding-bottom: 1.09375VW;
}
.bloquepartido {
  display: flex;
  width: 46VW;
  margin: auto;
  border: 1px solid #032a4b;
  border-radius: 2.604166666666667VW;
}
.bpmov {
  display: none;
}
.coluno-bp {
  width: 20%;
}
.coldos-bp {
  width: 80%;
}
.bloquefecha {
  background: #032a4b;
  height: 100%;
  width: 60%;
  float: right;
}
.txtfechamov {
  display: none;
}
.bloquefecha h2 {
  color: #b70d1d;
  font-size: 2.604166666666667VW;
  font-family: Rajdhani;
  padding-top: 2.1875VW;
}
.bloquefecha h4 {
  color: #ffffff;
  font-family: Rajdhani;
  font-size: 1.25VW;
  margin: 0;
  margin-top: -1vw;
}
.bloquefecha h6 {
  color: #ffffff;
  font-family: Rajdhani;
  font-size: 0.9375VW;
}
.coldos-bp h3 {
  text-align: left;
  padding-top: 2.8125VW;
  padding-left: 2.7083333333333335VW;
  color: #b70d1d;
  font-family: Rajdhani;
  font-size: 1.3020833333333335VW;

}
.coldos-bp p {
  color: #8293a1;
  font-family: Rajdhani;
  font-size: 0.7291666666666666VW;
  text-align: left;
  padding-left: 2.7083333333333335VW;
  margin-top: -.5vw;
}

.filaequipos {
  display: flex;
  padding-left: 2.7083333333333335VW;
}
.coluno-fp {
    display: flex;
    width: 35%;
}
.coldos-fp {
  width: 20%;
  text-align: center;
}
.coltres-fp {
    display: flex;
    width: 40%;
}
.filaequipos img {
  width: 3.3854166666666665VW;
  height: auto;
}
.filaequipos h5 {
  color: #032a4b;
  text-align: left;
  padding-left: 1vw;
  padding-top: .75vw;
}
.txtvs-p {
    font-size: .729vw;
    padding: .938vw .2vw;
    width: 2.656vw;
    height: 2.656vw;
    border: 1px solid #032a4b;
    border-radius: 2vw;
    text-align: center;
    color: #032a4b;
    margin: auto;
    font-family: Rajdhani;
}
.bmp a:hover {
  text-decoration: none;
}
.btnboletos {
  display: flex;
  background: #b70d1d;
  align-items: center;
  width: 17.239583333333332VW;
  margin: auto;
  margin-top: 2.083333333333333VW;
  padding: 1.5vw 0vw;
  cursor: pointer;
}
.btnboletos p {
  color: #ffffff;
  font-family: Rajdhani;
  font-size: 0.6770833333333334VW;
  margin: 0;
  width: 70%;
  text-align: right;
  padding-right: .8vw;
}
.btnboletos img {
  width: 2.8125VW;
  padding-bottom: 0vw;
}
.bloquesocial {
  padding-top: 3vw;
  padding-bottom: 3vw;
}
.bloquesocial .fbip {
  width: 0.5583333333333333VW;
  padding-bottom: 0vw;
  margin-right: 2.7604166666666665VW;
}
.bloquesocial .igip {
  width: 1.1609375VW;
  padding-bottom: 0vw;
  margin-right: 2.7604166666666665VW;
}
.bloquesocial .liip {
  width: 1.128125VW;
  padding-bottom: 0vw;
  margin-right: 2.7604166666666665VW;
}
.bloquesocial .twip {
  width: 1.2010416666666666VW;
  padding-bottom: 0vw;
}

}














@media (max-width: 1023px ) {
  .bloquemain {
  background-image: url(../assets/FONDOPROX_MOVIL.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
  padding-top: 17.14975845410628VW;
}
.bmp img {
  width: 34.29951690821256VW;
  padding-bottom: 11.352657004830919VW;
}
.bmp h1 {
  color: #032a4b;
  font-family: Rajdhani;
  font-weight: 800;
  margin: 0;
  font-size: 7.246376811594203VW;
  letter-spacing: 1vw;
  padding-bottom: 8VW;

}
.bmp h5 {
  color: #ce1526;
  font-family: Rajdhani;
  font-weight: 700;
  margin: 0;
  font-size: 3.6231884057971016VW;
  padding-bottom: 8vw;
}
.bloquepartido {
  margin: auto;
  border: 1px solid #032a4b;
  border-radius: 4.830917874396135VW;
  width: 71.98067632850241VW;
}
.bpmov {
  display: block;
}
.bppc {
  display: none;
}
.coluno-bp {
    display: flex;
}
.bloquefecha {
    background: #032a4b;
    width: 35%;
    margin-top: 8vw;
    border-right: 1px solid #fff !important;
    padding-top: 2vw;
}
.bloquejornada {
    background: #032a4b;
    width: 65%;
    margin-top: 8vw;
    padding-top: 2vw;
    padding-left: 5vw;
}
.bloquefecha h2 {
  color: #b70d1d;
  font-family: Rajdhani;
  font-size: 3.5vw;
  margin-right: 1vw;
}
.bloquefecha h4 {
  color: #b70d1d;
  font-family: Rajdhani;
  margin: 0;
  font-size: 3.6231884057971016VW;
  text-align: center;  
}
.bloquefecha h6 {
  color: #ffffff;
  font-family: Rajdhani;
  font-size: 4.3478260869565215VW;
}
.bloquejornada h3 {
  color: #b70d1d;
  font-family: Rajdhani;
  font-size: 3.864734299516908VW;
  text-align: left;
  margin: 0;
}
.bloquejornada p {
  color: #8293a1;
  font-size: 2.898550724637681VW;
  text-align: left;
}
.coldos-bp {
    padding-top: 4vw;
}
.coldos-bp h3 {
  color: #b70d1d;
  font-family: Rajdhani;
  background: #032a4b;
  text-align: right;
  font-size: 4.864734299516908VW;
  padding-right: 6vw;
  padding-bottom: 5vw;
  padding-top: 1vw;
}
.coldos-bp p {
  color: #8293a1;
  font-family: Rajdhani;
  font-size: 3.3816425120772946VW;
  padding-top: 3vw;
}
.coluno-fp {
  display: flex;
  width: 80%;
  margin: auto;
}
.coltres-fp {
  display: flex;
  width: 80%;
  margin: auto;
  padding-top: 1vw;
}
.filaequipos img {
  width: 10.38647342995169VW;
}
img.logovisp {
    width: 10vw !important;
    padding-left: 1vw;
}
.filaequipos h5 {
  color: #032a4b;
  font-size: 3.3816425120772946VW;
  padding-left: 4vw;
  padding-top: 3vw;
  height: fit-content;
}
.txtvs-p {
    border: 1px solid #032a4b;
    border-radius: 8vw;
    text-align: center;
    color: #032a4b;
    margin: auto;
    font-family: Rajdhani;
    width: 15%;
    padding-top: 3vw;
    padding-bottom: 2vw;
    margin-top: -12vw;
    margin-bottom: 1vw;
    font-size: 4vw;
}
.bmp a:hover {
  text-decoration: none;
}
.btnboletos {
  display: flex;
  background: #b70d1d;
  align-items: center;
  margin: auto;
  cursor: pointer;
  margin-top: 8vw;
  width: 73%;
  padding-top: 6vw;
  padding-bottom: 6vw;
  transition: .5s;
}
.btnboletos:hover {
    background: #830814;
    transition: .5s;
    
}
.btnboletos p {
  color: #ffffff;
  font-family: Rajdhani;
  margin: 0;
  font-size: 3.140096618357488VW;
  width: 67%;
  text-align: right;
}
.btnboletos img {
  width: 9.178743961352657VW;
  padding-bottom: 0vw;
  margin-left: 4vw;
}
.bloquesocial {
    display: flex;
    padding: 10vw 0vw 16vw 0vw;
    width: 60%;
    margin: auto;
}
.bloquesocial .fbip {
  width: 1.932367149758454VW;
  padding-bottom: 0vw;
  margin-right: 15VW;
}
.bloquesocial .igip {
  width: 4.094202898550725VW;
  padding-bottom: 0vw;
  margin-right: 15VW;
}
.bloquesocial .liip {
  width: 3.9782608695652173VW;
  padding-bottom: 0vw;
  margin-right: 15VW;
}
.bloquesocial .twip {
  width: 4.3478260869565215VW;
  padding-bottom: 0vw;
}



}


</style>


